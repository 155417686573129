<script>
import { wpService } from "@/services/wp";
export default {
  name: "account-org",

  metaInfo: {
    title: "Mi Organización",
  },

  data() {
    return {
      members: [],
    };
  },

  methods: {
    async create(gift_recipient, gift_amount) {
      const loading = this.$buefy.loading.open();
      await wpService.gifts
        .create(gift_recipient, gift_amount)
        .then(() => {
          this.$buefy.toast.open({
            message: `Regalo enviado`,
            type: "is-success",
          });
        })
        .catch(err => {
          console.log(err);
          this.$buefy.dialog.alert({
            title: "Algo salió mal",
            message: `Tu solicitud no pudo ser procesada.`,
            type: "is-danger",
          });
        });
      loading.close();
    },
    sendGift(member) {
      const self = this;
      this.$buefy.dialog.prompt({
        title: `Regalar créditos`,
        message: `<b>Para:</b> ${member.nickname}<br/>
                  <b>Cantidad:</b>`,
        inputAttrs: {
          type: "number",
          placeholder: "Cantidad de créditos",
          value: "1",
          max: 50,
          min: 1,
        },
        trapFocus: true,
        onConfirm: amount => self.create(member.ID, amount),
      });
    },
  },
};
</script>

<template>
  <div id="account_org" class="">
    <div class="">
      <div class="page__header ui_padding container">
        <h1 class="page__title">Mi Organización</h1>
      </div>
      <div class="page__section ui_padding container"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.member-row {
  display: grid;
  align-items: center;
  margin-bottom: 1rem;
}
.avatar {
  .avatar__container {
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  .size-holder {
    display: block;
  }
  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.sponsoring {
  > * {
    display: flex;
    line-height: 1.2;
    align-items: center;
    font-size: 0.75em;
  }
  .icon {
    margin-right: 0.375rem;
  }
}
@media (max-width: 39.9375em) {
  .member-row {
    display: grid;
    grid-template-columns: 3.5rem 1fr;
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.375rem;
  }
  .sponsoring,
  .tools {
    grid-column: span 2;
  }
}
@media (min-width: 40em) {
  .member-row {
    display: grid;
    width: 100%;
    // justify-content: space-between;
    grid-template-columns: 4rem 1fr 1fr;
    grid-gap: 0.75rem;

    .tools {
      text-align: right;
    }
  }
}
</style>
